import React, { ButtonHTMLAttributes } from 'react'
import * as Styles from './styles'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  background?: 'default' | 'grey' | 'green' | 'black' | 'red' | 'blue' | 'primary' | 'transparent'
  isOutlined?: boolean
  height?: number
}

const POSSIBLE_BACKGROUNDS = {
  default: '#f0780a',
  grey: '#8F8F8F',
  black: '#424242',
  green: '#464F31',
  red: '#D82626',
  blue: '#4579FF',
  primary: "#748351",
  transparent:"#FFFFFF00"
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  background = 'default',
  isOutlined = false,
  height = 45,
  ...rest
}) => {
  return (
    <Styles.ButtonContainer
      onClick={onClick}
      background={POSSIBLE_BACKGROUNDS[background]}
      isOutlined={isOutlined}
      height={height}
      {...rest}
    >
      {children}
    </Styles.ButtonContainer>
  )
}

export default Button;
