import styled from 'styled-components'
import { theme } from 'design-cannect'

interface propsLoading {
  color?: string
}
export const Container = styled.div<propsLoading>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;

  p{
    font-size: 1rem;
    color: white;
    font-weight: bold;
    margin-top: 1rem;
  }
  svg {
    color: ${({ color }) => color || theme.colors.tertiary};
    width: 50px;
    height: 50px;
    animation: normal rotate-center 1s ease-in-out infinite both;
  }

  @keyframes rotate-center {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
