import React from 'react'
import { IoMdClose } from 'react-icons/io'
import * as Styles from './styles'

export interface ModalProps {
  setOpenModal: any
  children: any
  isOpen?: boolean
  title?: string
  hiddenClose?: boolean
  bannerType?: 'static'
  returnAction?: () => void
  returnText?: string
  withRadius?: boolean
  bgColor?: string
  maxHeight?: string
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  setOpenModal,
  children,
  returnAction,
  returnText,
  hiddenClose,
  title,
  bannerType,
  withRadius = true,
  bgColor,
  maxHeight = '85vh'
}) => {
  return (
    <Styles.DialogRoot open={isOpen} onOpenChange={setOpenModal}>
      <Styles.DialogPortal>
        <Styles.DialogOverlay />
        <Styles.DialogContent bannerType={bannerType} withRadius={withRadius} bgColor={bgColor} maxHeight={maxHeight}>
          {!!title && (
            <Styles.DialogTitle className="DialogTitle">
              <p>{title}</p>
            </Styles.DialogTitle>
          )}
          {!!returnText && !title && <Styles.ReturnAction onClick={returnAction}>{returnText}</Styles.ReturnAction>}
          {!hiddenClose && (
            <Styles.DialogClose onClick={() => setOpenModal(false)}>
              <IoMdClose />
            </Styles.DialogClose>
          )}
          <Styles.ContentChildren>{children}</Styles.ContentChildren>
        </Styles.DialogContent>
      </Styles.DialogPortal>
    </Styles.DialogRoot>
  )
}

export default Modal
