import { noop } from 'lodash'
import React from 'react'
import Button from './Button'
import Modal from './Modal'
import * as Styles from './styles'

interface StatusModalProps {
  isOpen: boolean
  onClose: any
  notCloseModal?: boolean
  title?: string
  text?: React.ReactNode
  icon: any
  textButton: string
  variant?: 'success' | 'error'
  onClickButton: any
}

const StatusModal = ({
  isOpen,
  onClose = noop,
  notCloseModal = false,
  text,
  title,
  icon,
  textButton,
  variant = 'success',
  onClickButton = noop
}: StatusModalProps) => {
  const color = variant === 'success' ? 'green' : 'red'

  return (
    <Modal isOpen={isOpen} setOpenModal={notCloseModal ? noop : onClose} title="Status do pagamento">
      <Styles.ModalContent color={variant}>
        <div className="icon-box">{icon}</div>
        <div className="error-title">{title}</div>
        <div className="error-details">
          {typeof text === 'object' ? (
            text
          ) : (
            <div className="detail-item">
              <span className="value">{text}</span>
            </div>
          )}
        </div>
        <div className="action-box">
          <Button isOutlined background={color} onClick={onClickButton} style={{marginTop: '20px'}}>
            {textButton}
          </Button>
        </div>
      </Styles.ModalContent>
    </Modal>
  )
}

export default StatusModal
