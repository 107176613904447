/* eslint-disable no-undef */
import { Loader } from 'lucide-react';

import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { BarcodeComponent } from './BarCode';
import * as S from './styles';

interface BoletoProps {
  isValidateSubmit: boolean;
  setError: (error: boolean) => void;
  isCardBoleto?: boolean;
  boletoAmount?: number;
  order: any;
}

const Boleto = ({ isValidateSubmit, setError, isCardBoleto = false, boletoAmount = 0, order }: BoletoProps) => {
  const [isGeneratingBoleto, setIsGeneratingBoleto] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [chargeId, setChargeId] = useState(null)
  const lastOrderCreated = order
  interface BoletoInfo {
    barcode: string;
    line: string;
    url: string;
    pdf: string;
  }

  const [boletoInfo, setBoletoInfo] = useState<BoletoInfo | null>(null)


  const handleGenerateBoleto = async () => {
    setIsGeneratingBoleto(true)
    try {
      const payload = {
        order_id: lastOrderCreated.id,
        payments: [
          {
            paymentMethod: { type: 'boleto' },
            amount: Math.round(lastOrderCreated.amount * 100)
          }
        ]
      }

      const response = await api.post('/payments/charges', payload)

      if (response.data.success && response.data.responses && response.data.responses.length > 0) {
        const paymentResponse = response.data.responses[0]
        if (paymentResponse.last_transaction && paymentResponse.last_transaction.success) {
          setChargeId(paymentResponse.id)

          setIsRedirecting(true)
        } else {
          throw new Error('Transação do boleto não foi bem-sucedida')
        }
      } else {
        throw new Error('Falha ao gerar boleto')
      }
    } catch (error) {
      console.error('Erro ao gerar boleto:', error)
      setError(true)
    } finally {
      setIsGeneratingBoleto(false)
    }
  }

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timer | undefined

    const checkChargeStatus = async () => {
      if (chargeId) {
        try {
          const response = await api.get(`payments/charge/${lastOrderCreated.id}/${chargeId}`)
          if (response.data.success) {
            const transaction = response.data.charge.transactions.find(
              (t: { external_id: any }) => t.external_id === response.data.charge.last_transaction_external_id
            )
            if (transaction && transaction.status === 'generated') {
              const boletoInfo = transaction.payments_methods.find((method: { transaction_external_id: any; }) => method.transaction_external_id === transaction.external_id)

              if (boletoInfo) {
                setBoletoInfo(boletoInfo)
                window.open(boletoInfo.url, '_blank')
                setIsRedirecting(false)
              }
            }
          }
        } catch (error) {
          console.error('Erro ao verificar status do boleto:', error)
        }
      }
    }

    if (isRedirecting) {
      intervalId = setInterval(checkChargeStatus, 2000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isRedirecting, chargeId, lastOrderCreated.id])

  if (isRedirecting && !boletoInfo) {
    return (
      <S.ConfirmationCard>
        <S.CardHeader>
          <h2>Gerando boleto</h2>
        </S.CardHeader>
        <S.CardContent>
          <S.RedirectingScreen>
            <Loader className="mb-4 animate-spin" size={48} />
            <p>Por favor, aguarde. Vamos te redirecionar para a pagina do boleto.</p>
          </S.RedirectingScreen>
        </S.CardContent>
      </S.ConfirmationCard>
    )
  }

  if(boletoInfo){
    return (
      <S.ConfirmationCard>
        <S.CardHeader>
          <h2>Dados do boleto</h2>
        </S.CardHeader>
        <S.CardContent>
          <BarcodeComponent value={boletoInfo.barcode}/>
          <span>{boletoInfo.line}</span>
          <S.ButtonContainer>
            <S.Button onClick={() => window.open(boletoInfo.url, '_blank')} style={{marginTop: '20px'}}>Vizualizar Boleto</S.Button>
          </S.ButtonContainer>
        </S.CardContent>
      </S.ConfirmationCard>
    )
  }

  return (
    <S.ConfirmationCard>
      <S.CardHeader>
        <h2>Confirmação de Pagamento via Boleto</h2>
      </S.CardHeader>
      <S.CardContent>
        <S.OrderDetails>
          <div className="detail-row">
            <span>Valor total:</span>
            {isCardBoleto ? (
              <strong>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(boletoAmount)}
              </strong>
            ) : (
              <strong>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(lastOrderCreated.amount)}
              </strong>
            )}
          </div>
          <div className="detail-row">
            <span>Método de pagamento:</span>
            <strong>Boleto Bancário</strong>
          </div>
          <div className="detail-row">
            <span>Prazo de pagamento:</span>
            <strong>3 dias úteis</strong>
          </div>
        </S.OrderDetails>
        <S.InfoText>
          <p>Ao confirmar, você receberá um boleto bancário para realizar o pagamento.</p>
          <p>O pagamento deve ser realizado em até 3 dias úteis para garantir a confirmação do seu pedido.</p>
          <p>O prazo para compensação do pagamento é de até 3 dias úteis.</p>
        </S.InfoText>
        <S.ButtonContainer>
          {!isCardBoleto && (
            <S.Button onClick={handleGenerateBoleto} disabled={isGeneratingBoleto || isValidateSubmit}>
              {isGeneratingBoleto ? (
                <>
                  <Loader className="mr-2 animate-spin" />
                  Gerando boleto...
                </>
              ) : (
                'Gerar Boleto'
              )}
            </S.Button>
          )}
        </S.ButtonContainer>
      </S.CardContent>
    </S.ConfirmationCard>
  )
}

export default Boleto
