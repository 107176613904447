import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
`

export const PaymentFormContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

export const PaymentFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: #f8f8f8;
  border-bottom: 1px solid #e5e5e5;
`

export const BackButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: ${({ disabled }) => (disabled ? '#ccc' : '#748351')};
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: color 0.2s ease;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  &:hover {
    color: ${({ disabled }) => (disabled ? '#ccc' : '#5a6840')};
  }
`

export const SelectedMethodInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  color: #1c1c1c;

  svg {
    color: #006de6;
  }
`

export const PaymentSelectorContainer = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`

export const PaymentSectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #1c1c1c;
  margin-bottom: 24px;
`

export const PaymentOptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const PaymentOptionsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const GroupTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom: 8px;
`

export const OptionsRow = styled.div<any>`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const PaymentOption = styled.button<{ isSelected: boolean; isMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  padding: ${({ isMobile }) => (isMobile ? '10px' : '16px')};
  background: ${({ isSelected }) => (isSelected ? '#F0F7FF' : '#FFFFFF')};
  border: 2px solid ${({ isSelected }) => (isSelected ? '#006DE6' : '#E5E5E5')};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  min-width: 250px;
  max-width: ${({ isMobile }) => (isMobile ? '100%' : '300px')};
  width: 100%;
  text-align: left;
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};

  &:hover {
    border-color: #006de6;
    background: ${({ isSelected }) => (isSelected ? '#F0F7FF' : '#F8F8F8')};
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ isMobile }) => (isMobile ? '40px' : '48px')};
    height: ${({ isMobile }) => (isMobile ? '40px' : '48px')};
    background: ${({ isSelected }) => (isSelected ? '#006DE6' : '#F0F0F0')};
    border-radius: 24px;
    margin-bottom: ${({ isMobile }) => (isMobile ? '0px' : '16px')};

    svg {
      color: ${({ isSelected }) => (isSelected ? '#FFFFFF' : '#4A4A4A')};
      width: ${({ isMobile }) => (isMobile ? '18px' : '24px')};
    }
  }

  .combined-icons {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

    .plus-icon {
      color: #4a4a4a;
    }
  }

  .method-info {
    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #1c1c1c;
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      color: #4a4a4a;
      margin-bottom: 12px;
    }
  }

  .amount {
    font-size: 16px;
    font-weight: 600;
    color: #1c1c1c;
  }

  .check-indicator {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    background: #006de6;
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      border-color: #e5e5e5;
      background: #ffffff;
    }
  }
`
