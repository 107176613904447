import styled from 'styled-components'

export const ConfirmationCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 800px;
  overflow: hidden;
  margin: 0 auto;
`

export const CardHeader = styled.div`
  padding: 24px;
  background: #f8fafc;
  border-bottom: 1px solid #e5e5e5;

  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #1c1c1c;
  }
`

export const CardContent = styled.div`
  padding: 24px;
`

export const OrderDetails = styled.div`
  margin-bottom: 24px;

  .detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #e5e5e5;

    &:last-child {
      border-bottom: none;
    }

    span {
      color: #666;
      font-size: 14px;
    }

    strong {
      color: #1c1c1c;
      font-size: 16px;
      font-weight: 600;
    }
  }
`

export const InfoText = styled.div`
  margin-bottom: 24px;

  p {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  max-width: 300px;
  padding: 12px 24px;
  background: #748351;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #5a6840;
  }

  &:disabled {
    background: #e5e5e5;
    cursor: not-allowed;
  }

  .animate-spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const RedirectingScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;

  p {
    font-size: 16px;
    color: #666;
    margin-top: 16px;
  }

  .animate-spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
