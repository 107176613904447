import useMediaQuery from 'hooks/useMediaQuery'
import { OrderProps, useOrderB2c } from 'hooks/useOrderB2c'
import { ArrowLeft, CreditCard, Receipt, Wallet } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MOBILE_WIDTH } from 'utils/constants'
import Boleto from './Boleto'
import { CreditCardComponent } from './CreditCart'
import * as S from './styles'

import PaymentMethodSelector from './PaymentMethodSelector'
import { Pix } from './Pix'

interface PaymentMethodProps {
  disabledPix?: boolean
  disabledCreditCard?: boolean
  disabledBoleto?: boolean
  error: boolean
  isValidateSubmit?: boolean
  loadingContainer: boolean
  clearIntervalOutside: () => void
  hasMultipleMethods?: boolean
  order: OrderProps
}

export interface BarcodeInfoProps {
  amount: number
  boleto_barcode: string
  boleto_expiration_date: Date
  boleto_url: string
}


export const PaymentMethod = ({
  disabledPix,
  disabledCreditCard,
  disabledBoleto,
  error,
  isValidateSubmit = false,
  loadingContainer,
  clearIntervalOutside,
  hasMultipleMethods,
  order
}: PaymentMethodProps) => {
  const [barcodeInfo, setBarcodeInfo] = useState<any>({
    amount: 0,
    boleto_barcode: "",
    boleto_expiration_date: new Date(),
    boleto_url: ""
  })

  const [pixInfo, setPixInfo] = useState<string>('')
  const [generateBoleto, setGenerateBoleto] = useState<boolean>(false)
  const { paymentMethod, setPaymentMethod, finishOrderCheckoutByType, setError } = useOrderB2c()
  const {uuid} = useParams() as any
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null)
  const [showSelector, setShowSelector] = useState(true)
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const lastOrderCreated  = order


  const isMobile = useMediaQuery(MOBILE_WIDTH)

  useEffect(() => {
    return () => {
      clearIntervalOutside()
    }
  }, [clearIntervalOutside])

  const handleMethodSelect = (method: string) => {
    setSelectedMethod(method)
    setShowSelector(false)
  }

  const handleBackToSelector = () => {
    if (!isProcessingPayment) {
      setShowSelector(true)
      setSelectedMethod(null)
    }
  }

  const getMethodIcon = (method: string) => {
    switch (method) {
      case 'credit_card':
      case 'credit_card_credit_card':
      case 'credit_card_boleto':
        return <CreditCard size={24} />
      case 'pix':
      case 'credit_card_pix':
        return <Wallet size={24} />
      case 'boleto':
        return <Receipt size={24} />
      default:
        return null
    }
  }

  const getMethodTitle = (method: string) => {
    switch (method) {
      case 'credit_card':
        return 'Cartão de Crédito'
      case 'pix':
        return 'Pix'
      case 'boleto':
        return 'Boleto Bancário'
      case 'credit_card_credit_card':
        return 'Dois Cartões de Crédito'
      case 'credit_card_pix':
        return 'Cartão de Crédito + Pix'
      case 'credit_card_boleto':
        return 'Cartão de Crédito + Boleto'
      default:
        return ''
    }
  }

  const renderPaymentComponent = () => {
    switch (selectedMethod) {
      case 'credit_card':
      case 'credit_card_credit_card':
      case 'credit_card_pix':
      case 'credit_card_boleto':
        return (
          <CreditCardComponent
            order={order}
            loadingContainer={loadingContainer}
            paymentMethod={
              selectedMethod as 'credit_card' | 'credit_card_credit_card' | 'credit_card_pix' | 'credit_card_boleto'
            }
          />
        )
      case 'pix':
        return <Pix loadingContainer={loadingContainer} order={order}/>
      case 'boleto':
        return <Boleto isValidateSubmit={isValidateSubmit} setError={setError} order={order}/>
      default:
        return null
    }
  }

  console.log(typeof lastOrderCreated.amount)

  return (
    <S.Container>
      {showSelector ? (
        <PaymentMethodSelector
          selectedMethod={selectedMethod}
          onSelectMethod={handleMethodSelect}
          totalAmount={typeof lastOrderCreated?.amount === 'number' ? lastOrderCreated.amount : Number(lastOrderCreated.amount)}
          disabledPix={disabledPix}
          disabledCreditCard={disabledCreditCard}
          disabledBoleto={disabledBoleto}
          hasMultipleMethods={hasMultipleMethods}
        />
      ) : (
        <S.PaymentFormContainer>
          <S.PaymentFormHeader>
            <S.BackButton onClick={handleBackToSelector} style={{ color: '#748351' }} disabled={isProcessingPayment}>
              <ArrowLeft size={24} style={{ color: isProcessingPayment ? '#ccc' : '#748351' }} />
              Formas de pagamento
            </S.BackButton>
            <S.SelectedMethodInfo>
              <span style={{ color: '#748351' }}>{getMethodIcon(selectedMethod || '')}</span>
              <span>{getMethodTitle(selectedMethod || '')}</span>
            </S.SelectedMethodInfo>
          </S.PaymentFormHeader>
          {renderPaymentComponent()}
        </S.PaymentFormContainer>
      )}
    </S.Container>
  )
}
