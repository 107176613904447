/* eslint-disable import/no-extraneous-dependencies */
import * as Dialog from '@radix-ui/react-dialog'
import styled, { css, keyframes } from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'
import { ModalProps } from '.'
import { media } from '../../../../../helpers/mediaQueryStyle'
import { temporaryTheme } from '../../../../../styles/theme'

type ContentProps = Dialog.DialogContentProps & Pick<ModalProps, 'bannerType' | 'withRadius' | 'bgColor' | 'maxHeight'>

const contentShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.86);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const ContentModifier = {
  static: () => css`
    padding: 0;
    max-width: unset;
    max-height: unset;
    overflow: hidden;
    height: auto;

    @media ${MOBILE_WIDTH} {
      width: fit-content;
      padding-top: 0;
    }
  `
}

export const DialogRoot = styled(Dialog.Root)``
export const DialogTitle = styled(Dialog.Title)`
  font-family: 'Spartan', sans-serif;
  font-size: 14px;
  min-height: 50px;
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;

  p {
    width: 70%;
  }
`

export const DialogPortal = styled(Dialog.Portal)``
export const ReturnAction = styled.div`
  padding: 4px;
  border: none;
  position: absolute;
  top: 24px;
  left: 24px;
  font-family: 'Spartan', sans-serif;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`

export const DialogOverlay = styled(Dialog.Overlay)`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  inset: 0;
  animation: ${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1001;
`

export const DialogContent = styled(Dialog.Content)<ContentProps>`
  ${({ bannerType, withRadius, bgColor = 'white', maxHeight }) => css`
    background: ${bgColor};
    border-radius: 12px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    max-width: 80vw;
    max-height: {maxHeight}
    animation: ${contentShow} 200ms cubic-bezier(0.16, 1, 0.3, 1);
    overflow-x: auto;
    z-index: 1002;
    padding: 25px;
    &:focus {
      outline: none;
    }

    ${media.mobile`
      width: 100%;
      padding: 12px;
      max-width: 70vw;

    `}

    border-radius: ${withRadius ? '12px' : 0};

    ${!!bannerType && ContentModifier[bannerType]()};
  `}
`

export const ContentChildren = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`

export const DialogClose = styled(Dialog.Close)`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${temporaryTheme.colors.darkGrey};
  padding: 4px;
  border: none;
  position: absolute;
  top: 12px;
  right: 12px;
  svg {
    color: white;
    width: 100%;
    height: 100%;
  }
`

export const ButtonClose = styled.button``
