import { css, FlattenSimpleInterpolation } from 'styled-components'
import { breakpoints } from '../utils/breakpoints'

interface mediaProps {
  mobile: any
  tablet: any
  mobileWithTablet: any
  desktop: any
}

export const media: mediaProps = {
  mobile: (styles: FlattenSimpleInterpolation | string | any) => css`
    @media screen and (max-width: ${breakpoints.mobile}) {
      ${typeof styles === 'string'
        ? css`
            ${styles}
          `
        : styles}
    }
  `,
  tablet: (styles: FlattenSimpleInterpolation | string) => css`
    @media screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      ${typeof styles === 'string'
        ? css`
            ${styles}
          `
        : styles}
    }
  `,
  mobileWithTablet: (styles: any) => css`
    @media screen and (max-width: ${breakpoints.tablet}) {
      ${typeof styles === 'string'
        ? css`
            ${styles}
          `
        : styles}
    }
  `,
  desktop: (styles: FlattenSimpleInterpolation | string) => css`
    @media screen and (min-width: ${breakpoints.desktop}) {
      ${typeof styles === 'string'
        ? css`
            ${styles}
          `
        : styles}
    }
  `
}
