/* eslint-disable no-nested-ternary */
import { motion } from 'framer-motion'
import styled, { css, keyframes } from 'styled-components'

import { Select } from 'antd'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const slideIn = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

export const Container = styled.div<any>`
  animation: ${fadeIn} 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${({ isMobile }) => (isMobile ? '0 14px' : '0 24px')};
  margin: 0 auto;
`

export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  perspective: 1000px;
  margin-bottom: 16px;
`

export const CardContainer = styled.div<{ isFlipped: boolean }>`
  position: relative;
  width: 100%;
  height: 200px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: ${(props) => (props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0)')};
`

export const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 16px;
  padding: 24px;

  &.front {
    background: linear-gradient(135deg, #748351 0%, #5a6840 100%);
    color: white;
  }

  &.back {
    background: linear-gradient(135deg, #5a6840 0%, #3d4a2a 100%);
    color: white;
    transform: rotateY(180deg);

    .magnetic-stripe {
      width: 100%;
      height: 40px;
      background: rgba(0, 0, 0, 0.4);
      margin: 20px 0;
    }

    .cvv-container {
      padding: 0 24px;
      text-align: right;

      label {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      .cvv-box {
        display: inline-block;
        background: white;
        color: #1c1c1c;
        padding: 8px 16px;
        border-radius: 4px;
        font-family: monospace;
        letter-spacing: 0.2em;
      }
    }
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .chip {
      width: 60px;
      height: 45px;
      background: linear-gradient(135deg, #ffd700 0%, #b8860b 100%);
      border-radius: 8px;
    }

    .brand-logo {
      height: 45px;
      object-fit: contain;
      filter: brightness(0) invert(1);
    }
  }

  .card-body {
    .card-number {
      font-size: 22px;
      letter-spacing: 0.1em;
      margin-bottom: 40px;
      font-family: monospace;
      word-spacing: 0.2em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-info {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      label {
        font-size: 12px;
        text-transform: uppercase;
        opacity: 0.8;
        margin-bottom: 4px;
      }

      div {
        font-size: 16px;
        letter-spacing: 0.05em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
      }
    }
  }
`

interface InputContainerProps {
  isFocused?: boolean
  hasError?: boolean
  hasBrandIcon?: boolean
}

export const InputContainer = styled.div<{ isFocused?: boolean; hasError?: boolean }>`
  position: relative;
  width: 100%;

  input,
  select {
    width: 100%;
    padding: 12px;
    padding-right: ${(props) => (Array.isArray(props.children) && props.children[1] ? '48px' : '12px')};
    border: 1px solid ${({ isFocused, hasError }) => (hasError ? '#FF4D4F' : isFocused ? '#748351' : '#E5E5E5')};
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.2s ease;
    background: white;
    color: #1c1c1c;
    height: 44px;

    &:hover {
      border-color: ${({ hasError }) => (hasError ? '#FF4D4F' : '#748351')};
    }

    &:focus {
      outline: none;
      border-color: #748351;
    }
  }

  .card-brand-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`

export const InstallmentContainer = styled.div`
  width: 100%;
  position: relative;

  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    height: 44px !important;
    padding: 0 12px !important;
    display: flex;
    align-items: center;
    border: 1px solid #e5e5e5 !important;
    border-radius: 8px !important;
    transition: all 0.2s ease !important;
    font-size: 14px !important;

    &:hover {
      border-color: #748351 !important;
    }
  }

  .ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
    height: 42px !important;
    line-height: 42px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 42px !important;
  }

  .ant-select-dropdown {
    width: auto !important;
    min-width: 100% !important;
  }
`

export const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.2s ease;
  flex: 1;

  ${({ variant = 'primary' }) =>
    variant === 'primary'
      ? css`
          background: #748351;
          color: white;
          border: none;

          &:hover {
            background: #5a6840;
          }

          &:disabled {
            background: #e5e5e5;
            cursor: not-allowed;
          }
        `
      : css`
          background: white;
          color: #748351;
          border: 2px solid #748351;

          &:hover {
            background: rgba(116, 131, 81, 0.1);
          }

          &:disabled {
            border-color: #e5e5e5;
            color: #999;
            cursor: not-allowed;
          }
        `}
`

export const BtnAddPaymentMethod = styled(Button)`
  background-color: transparent;
  color: #748351;
  border: 2px solid #748351;

  &:hover {
    background-color: rgba(116, 131, 81, 0.1);
  }

  &:disabled {
    background-color: transparent;
    color: #999;
    border-color: #999;
  }
`

export const BtnFinishOrder = styled(Button)`
  background-color: #748351;
  color: white;
  border: none;
  width: 50%;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #5a6840;
  }

  &:disabled {
    background-color: #e5e5e5;
    color: #999;
  }
`

export const Methods = styled.div`
  display: flex;
  gap: 16px;
`

export const ButtonMethod = styled.button<{ isActive?: boolean }>`
  padding: 12px 16px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  background-color: ${({ isActive }) => (isActive ? '#748351' : 'white')};
  color: ${({ isActive }) => (isActive ? 'white' : '#1c1c1c')};
  border: 2px solid ${({ isActive }) => (isActive ? '#748351' : '#E5E5E5')};

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '#5A6840' : '#F5F5F5')};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  img {
    width: 24px;
    height: 24px;
  }
`

export const BoletoRequest = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;

  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    margin-top: 16px;
    color: #666;
  }
`

export const ButtonCopy = styled(Button)`
  width: 100%;
`

export const SecondPaymentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const PaymentMethodsGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`

export const PaymentMethodColumn = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PercentageSection = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 24px 0 16px 0;
`

export const PercentageTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #666;
  margin-bottom: 8px;
`

export const CustomValueInput = styled.div`
  position: relative;
  width: 100px;
  height: 36px;

  input {
    width: 100%;
    height: 100%;
    padding: 8px 12px 8px 28px;
    background: white;
    border: 2px solid #748351;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #748351;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background: rgba(116, 131, 81, 0.1);
      outline: none;
    }
  }

  &::before {
    content: 'R$';
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #748351;
    font-size: 14px;
    font-weight: 600;
    pointer-events: none;
    z-index: 2;
    background: transparent;
  }
`

export const PercentageButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;

  ${CustomValueInput} {
    margin: 0;
  }
`

export const PercentageButton = styled.button<{ isSelected: boolean }>`
  padding: 8px 16px;
  height: 36px;
  background: ${({ isSelected }) => (isSelected ? '#748351' : 'white')};
  color: ${({ isSelected }) => (isSelected ? 'white' : '#748351')};
  border: 2px solid #748351;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ isSelected }) => (isSelected ? '#5A6840' : 'rgba(116, 131, 81, 0.1)')};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 32px;
  width: 100%;
  max-width: 500px;
`

export const CardSection = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  height: fit-content;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  max-width: 500px;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: #f8fafc;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #e5e5e5;

  &:hover {
    background: #f1f5f9;
  }
`

export const CardHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
  }

  span {
    color: #1a1a1a;
    font-weight: 500;
  }

  small {
    font-size: 14px;
    color: #748351;
    font-weight: 500;
    background: rgba(116, 131, 81, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
  }
`

export const LoadingText = styled.span`
  color: #748351;
  font-size: 14px;
  font-weight: 500;
`

export const CardContent = styled.div<{ isOpen?: boolean; isMobile?: boolean }>`
  display: ${({ isOpen }) => (isOpen === false ? 'none' : 'block')};
  padding: ${({ isMobile }) => (isMobile ? '20px' : '32px')};
`

export const ValueInput = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  input {
    width: 200px;
    height: 48px;
    padding: 12px;
    border: 2px solid #e5e5e5;
    border-radius: 8px;
    font-size: 16px;
    color: #1c1c1c;

    &:focus {
      outline: none;
      border-color: #748351;
    }

    &:hover {
      border-color: #748351;
    }
  }

  span {
    font-size: 14px;
    color: #666;
  }
`

export const OptionInstallment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  width: 100%;

  .values_installment {
    font-size: 14px;
    color: #1c1c1c;
    margin: 0;
    font-weight: 500;
  }

  .total_installment {
    font-size: 14px;
    color: #666;
    margin: 0;
    text-align: right;

    &.not_installment {
      color: #748351;
      font-weight: 500;
    }
  }
`

export const StyledSelect = styled(Select)`
  .ant-select-dropdown {
    max-height: 400px;
    overflow-y: auto;
  }
`

export const ProcessingPayment = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #748351;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    color: #666;
  }
`

export const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #748351;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const SuccessMessage = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;

  .success-icon {
    color: #748351;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #748351;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    color: #666;
  }
`

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;

  .select-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 44px;
    padding: 0 12px;
    background: white;
    border: 2px solid #e5e5e5;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1;
    gap: 8px;
    outline: none;
    transition: all 0.2s ease;

    &:hover {
      border-color: #748351;
    }

    &:focus {
      border-color: #748351;
      box-shadow: 0 0 0 2px rgba(116, 131, 81, 0.1);
    }

    &[data-placeholder] {
      color: #999;
    }

    &[data-disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .select-icon {
    color: #748351;
    width: 16px;
    height: 16px;
  }

  .select-content {
    background: white;
    border-radius: 8px;
    box-shadow:
      0 10px 38px -10px rgba(22, 23, 24, 0.35),
      0 10px 20px -15px rgba(22, 23, 24, 0.2);
    overflow: hidden;
    width: var(--radix-select-trigger-width);
    max-height: var(--radix-select-content-available-height);
  }

  .select-viewport {
    padding: 8px;
  }

  .select-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    user-select: none;
    outline: none;
    cursor: pointer;

    &[data-highlighted] {
      background: rgba(116, 131, 81, 0.1);
    }

    &[data-state='checked'] {
      background: #748351;
      color: white;

      .total_installment {
        color: white;

        &.not_installment {
          color: #e0e7d3;
        }
      }
    }
  }

  .select-item-indicator {
    position: absolute;
    right: 8px;
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .select-item-check {
    width: 14px;
    height: 14px;
    color: white;
  }

  .select-scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background: white;
    cursor: default;
    color: #748351;

    &:hover {
      background: #f8f8f8;
    }
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const FormRow = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  &.card-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }
`

export const Link = styled.a`
  color: #748351;
  text-decoration: underline;

  &:hover {
    color: #5a6840;
  }
`

export const PixQRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }

  img {
    max-width: 200px;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    margin-bottom: 0.5rem;
    color: #666;

    &:last-child {
      font-weight: bold;
      color: #333;
    }
  }
`

export const BoletoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`

export const BoletoDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 10px;
`

export const BoletoActions = styled.div`
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin-top: 10px;
`

export const BoletoButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
`
export const RedirectMessage = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: #f0f9f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .success-icon {
    color: #4caf50;
    margin-bottom: 1rem;
  }

  h3 {
    color: #2e7d32;
    margin-bottom: 0.5rem;
  }

  p {
    color: #1b5e20;
  }
`

export const ConfirmationCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 600px;
  overflow: hidden;
`

export const PixCard = styled(ConfirmationCard)`
  max-width: 800px;
`

export const OrderDetails = styled.div`
  margin-bottom: 24px;

  .detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #e5e5e5;

    &:last-child {
      border-bottom: none;
    }

    span {
      color: #666;
      font-size: 14px;
    }

    strong {
      color: #1c1c1c;
      font-size: 16px;
      font-weight: 600;
    }
  }
`

export const InfoText = styled.div`
  margin-bottom: 24px;

  p {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ButtonCopied = styled(ButtonCopy)`
  background: #777777;
  cursor: default;

  &:hover {
    background: #777777;
  }
`

export const CountDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  max-width: 300px;
  padding: 10px 30px;
  margin: 0 auto 40px;
  text-align: center;
  font-weight: 600;
  color: #748351;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
`

export const CountDownContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #666;
    font-size: 14px;
    margin-top: 4px;
  }
`

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`

export const QrCodeinfos = styled.div`
  flex: 1;
  max-width: 400px;

  p {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`

export const ExpiredMessage = styled.div`
  text-align: center;
  color: #666;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;

  p {
    margin-bottom: 16px;
  }
`

export const PaymentStatus = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #748351;
`
