/* eslint-disable no-undef */
import { Modal } from 'components/Modal'

import useMediaQuery from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { FiDollarSign } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import { api } from 'services/api'
import { MOBILE_WIDTH } from 'utils/constants'
import { getApp } from 'utils/sub-domains'
import Loading from '../../components/Loading'
import { useOrderB2c } from '../../hooks/useOrderB2c'
import { Connector } from '../OrderB2c/StepsNavigator/Connector'
import { StepButton } from '../OrderB2c/StepsNavigator/StepButton'
import { DeliveryAddress } from './DeliveryAddress'
import { Disclaimer } from './Disclaimer'
import { PaymentMethod } from './PaymentMethod'
import { Product } from './Product'
import { Resume } from './Resume'
import * as Style from './styles'

export const Checkout = () => {
  const { loading, getOrderCheckout, orderCheckout } = useOrderB2c()
  const [modalInactiveFlowers, setModalInactiveFlowers] = useState<boolean>(false)
  const [modalOrderPaid, setModalOrderPaid] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const { uuid } = useParams() as any
  const [loadingContainer, setLoading] = useState(false)

  const brand = getApp()
  const inactiveItems = orderCheckout.items.filter(item => item.product.status_b2b === 'I')
  const activeItems = orderCheckout.items.filter(item => item.product.status_b2b !== 'I') || []
  const [error, setError] = useState(false)

  const link = `https://${brand}.cannect.life/checkout/${uuid}`

  const [text, setText] = useState('COPIAR')
  async function copyUrl() {
    navigator.clipboard.writeText(link ?? '')
    setText('COPIADO!')
  }

  useEffect(() => {
    getOrderCheckout(uuid)
  }, [])

  let interval: NodeJS.Timeout | undefined


  const clearIntervalOutside = () => {
    clearInterval(interval)
  }

  useEffect(() => {
    if (inactiveItems.length > 0) setModalInactiveFlowers(true)
    if (orderCheckout?.status?.payment_status?.status === 'paid') setModalOrderPaid(true)
  }, [orderCheckout])

  const updateCart = async (dataItems: any[]) => {
    setUpdating(true)
    const { data } = await api.put(`/order_cart_public/${orderCheckout?.id}`, { items: dataItems })
    setUpdating(false)
    setModalInactiveFlowers(false)
    getOrderCheckout(uuid)
  }

  const handleRemoveInactiveItems = () => {
    updateCart(activeItems)
  }

  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Style.Container isMobile={isMobile}>
      {modalOrderPaid && (
        <Modal isOpened={modalOrderPaid} className="modal">
          <Style.ModalOrderPaid>
            <FiDollarSign className="icon-paid" />
            <div className="text-paid">
              <h2>
                <strong>Este pedido já foi pago.</strong>
              </h2>
              <h3>
                O pedido <b>{orderCheckout?.id}</b> já foi pago.
              </h3>
              <p>
                Caso haja <strong>alguma dúvida</strong>, entre em <strong>contato</strong> com o{' '}
                <strong>seu vendedor</strong>.
              </p>
            </div>
          </Style.ModalOrderPaid>
        </Modal>
      )}
      {loading && <Loading loading={loading} />}
      <Style.StepsContainer>
        <div className="steps-name">
          <StepButton step="identification" active disabled={false} />
          {!isMobile && <span>identificação</span>}
        </div>

        <Connector active={false} disabled />
        <div className="steps-name">
          <StepButton step="select-products" active disabled={false} />
          {!isMobile && <span>seleção de produtos</span>}
        </div>

        <Connector active={false} disabled />
        <div className="steps-name">
          <StepButton step="cart" active disabled={false} />
          {!isMobile && <span>orçamento</span>}
        </div>
      </Style.StepsContainer>
      <Style.Content isMobile={isMobile}>
        {!isMobile && (
          <Style.TableHeader>
            <div className="product">
              <p>produto</p>
            </div>
            <div className="product-properties">
              <p>quantidade</p>
              <p>valor unitário</p>
              <p>valor total</p>
            </div>
          </Style.TableHeader>
        )}

        <Style.Products isMobile={isMobile}>
          {orderCheckout.items.map((product, index) => {
            const value = orderCheckout?.coupon?.id ? product?.unitPrice : product?.imputed_value
            const price = value !== null && value !== undefined && Number(value) > 0 ? Number(value) : Number(product?.unitPrice)
            return (
              <>
                {index > 0 && isMobile && <Style.Line />}
                <Product
                  id={product.id}
                  name={product.product.translated_name}
                  real_price={price}
                  quantity={product.quantity}
                  image={
                    product.product.media.length > 0
                      ? product.product.media[0].path
                      : 'https://s3.amazonaws.com/cannect.portal.upload/produtos/14517/foto%201.jpg'
                  }
                />
              </>
            )
          })}
        </Style.Products>
      </Style.Content>

      <div className="column-cards">
      <Resume order={orderCheckout} />
        <DeliveryAddress />
      </div>

      <div className="payment-method">
      <PaymentMethod
        loadingContainer={loadingContainer}
        clearIntervalOutside={clearIntervalOutside}
        error={error}
        hasMultipleMethods
        order={orderCheckout}
      />
      </div>


      {orderCheckout.originCheckout === 'representante-usahemp' &&(
        <div className="column-cards">
          <Disclaimer />
        </div>
      )}
      <Modal isOpened={modalInactiveFlowers} className="modal">
        <div>
          <div>
            <h2>
              <strong>Este produto não está mais disponível.</strong>
            </h2>
          </div>
          {orderCheckout.items
            .filter(item => item.product.status === 'I')
            .map((item: any) => (
              <Style.WrapperProduct>
                <div className="product-image">
                  <img src={item.product?.media[0]?.path} alt="imagem do produto" />
                </div>
                <div>{item?.product?.translated_name}</div>
              </Style.WrapperProduct>
            ))}
          <Style.Button disabled={!!updating} onClick={() => handleRemoveInactiveItems()}>
            Estou ciente, continuar
          </Style.Button>
        </div>
      </Modal>
    </Style.Container>
  )
}
