import React from 'react'
import Barcode from 'react-barcode'
import styled from 'styled-components'

interface BarcodeProps {
  value: string
}

const BarcodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  overflow-x: auto;

  svg {
    max-width: 100%;
    height: auto;
  }
`

export const BarcodeComponent: React.FC<BarcodeProps> = ({ value }) => {
  return (
    <BarcodeWrapper>
      <Barcode value={value} width={1.5} height={140} format="CODE128" displayValue={false} background="#FFFFFF" />
    </BarcodeWrapper>
  )
}
