import { motion } from 'framer-motion'
import { CreditCardIcon } from 'lucide-react'
import React from 'react'

// Importe todos os SVGs
import AlipayIcon from '../../../../../assets/svg/cardBrand/alipay.svg'
import AmexIcon from '../../../../../assets/svg/cardBrand/amex.svg'
import DiscoverIcon from '../../../../../assets/svg/cardBrand/discover.svg'
import EloIcon from '../../../../../assets/svg/cardBrand/elo.svg'
import GenericIcon from '../../../../../assets/svg/cardBrand/generic.svg'
import HipercardIcon from '../../../../../assets/svg/cardBrand/hipercard.svg'
import JcbIcon from '../../../../../assets/svg/cardBrand/jcb.svg'
import MaestroIcon from '../../../../../assets/svg/cardBrand/maestro.svg'
import MastercardIcon from '../../../../../assets/svg/cardBrand/mastercard.svg'
import PaypalIcon from '../../../../../assets/svg/cardBrand/paypal.svg'
import VisaIcon from '../../../../../assets/svg/cardBrand/visa.svg'

type CardBrand =
  | 'alipay'
  | 'amex'
  | 'discover'
  | 'elo'
  | 'generic'
  | 'hipercard'
  | 'jcb'
  | 'maestro'
  | 'paypal'
  | 'visa'
  | 'mastercard'
  | 'diners'

interface CardBrandIconProps {
  brand?: CardBrand
  className?: string
}

const brandIcons = {
  alipay: AlipayIcon,
  amex: AmexIcon,
  discover: DiscoverIcon,
  elo: EloIcon,
  generic: GenericIcon,
  hipercard: HipercardIcon,
  jcb: JcbIcon,
  maestro: MaestroIcon,
  paypal: PaypalIcon,
  visa: VisaIcon,
  mastercard: MastercardIcon,
  diners: GenericIcon
}

export const CardBrandIcon: React.FC<CardBrandIconProps> = ({ brand, className }) => {
  if (!brand || !brandIcons[brand]) {
    return <CreditCardIcon size={24} className={className} />
  }

  const BrandIcon = brandIcons[brand]

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={className}>
      <img src={BrandIcon} width={32} height={32} alt={`${brand} icon`} />
    </motion.div>
  )
}

export default CardBrandIcon
