import { stringify } from 'query-string'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { normalizeFilters, removableFiltersKeys, removeKeyValuePairsByOmitObject } from './helpers'
import { FiltersList } from './types'

const useQueryString = () => {
  const { pathname, search: queryString } = useLocation()
  const history = useHistory()
  const filters: FiltersList = useMemo(() => normalizeFilters(queryString), [queryString])

  const changeFilters = useCallback(
    (nextFilters: FiltersList) => {
      const newFilters = removeKeyValuePairsByOmitObject(
        {
          ...filters,
          ...nextFilters
        },
        removableFiltersKeys
      )

      history.push(`${pathname}?${stringify(newFilters)}`)
    },
    [filters]
  )

  const clearFilters = useCallback(() => {
    const newFilters = removeKeyValuePairsByOmitObject({}, removableFiltersKeys)

    history.push(`${pathname}?${stringify(newFilters)}`)
  }, [])

  return {
    filters,
    changeFilters,
    clearFilters,
    queryString: queryString.replace('?', '')
  }
}

export default useQueryString
