import React from 'react'
import cannectLoading from 'assets/gifs/cannect-loading.gif'
import Text from 'components/Text'
import * as Styles from './styles'

interface propsLoading {
  loading: boolean
  textLoading?: string
}

const LoadingContainer = ({ loading, textLoading = 'CARREGANDO...' }: propsLoading) => {
  return (
    <span>
      {loading && (
        <Styles.Container>
          <img src={cannectLoading} alt="" width="80px" height="80px" />
            <p>
              CARREGANDO...
            </p>
        </Styles.Container>
      )}
    </span>
  )
}

export default LoadingContainer
